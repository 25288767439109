.desktop {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.desktop .div {
  background-color: #ffffff;
  height: 9573px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.desktop .overlap {
  height: 886px;
  left: 0;
  position: absolute;
  top: 5417px;
  width: 100%;
}

.desktop .group {
  background-color: #fff1e7;
  /* background-image: url(../../assets/img/group-46.png); */
  background-size: 100% 100%;
  height: 886px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.desktop .driving-change {
  color: transparent;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 44px;
  font-weight: 700;
  left: 140px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 70px;
}

.desktop .text-wrapper {
  color: #000000;
}

.desktop .span {
  color: #ffffff;
}

.desktop .text-wrapper-2 {
  color: #fc6e01;
}

.desktop .group-wrapper {
  height: 462px;
  left: 140px;
  position: absolute;
  top: 324px;
  width: 420px;
}

.group-2 {
  height: 462px;
  position: relative;
}

.overlap-group {
  background-image: url(../../assets/img/group-39205-4.png);
  background-size: 100% 100%;
  height: 270px;
  left: 0;
  position: absolute;
  top: 0;
  width: 420px;
}

.vector-wrapper {
  background-color: #ffffff;
  border-radius: 28px;
  height: 56px;
  left: 182px;
  position: relative;
  top: 107px;
  width: 56px;
}

.desktop .vector {
  height: 25px;
  left: 21px;
  position: absolute;
  top: 16px;
  width: 19px;
}

.desktop .div-wrapper {
  height: 192px;
  left: 0;
  position: absolute;
  top: 270px;
  width: 420px;
}

.desktop .group-3 {
  height: 192px;
}

.desktop .frame-wrapper {
  background-color: #ffffff;
  height: 192px;
  width: 420px;
}

.desktop .frame {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  left: 24px;
  position: relative;
  top: 34px;
  width: 323px;
}

.desktop .text-wrapper-3 {
  align-self: stretch;
  color: #fc6e01;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
  margin-top: -1px;
  position: relative;
}

.desktop .our-zero-poverty {
  align-self: stretch;
  color: #000000;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 27px;
  position: relative;
}

.desktop .group-4 {
  height: 462px;
  left: 600px;
  position: absolute;
  top: 324px;
  width: 420px;
}

.desktop .overlap-group-2 {
  background-image: url(../../assets/img/group-39205-5.png);
  background-size: 100% 100%;
  height: 270px;
  left: 0;
  position: absolute;
  top: 0;
  width: 420px;
}

.desktop .group-5 {
  height: 462px;
  left: 1060px;
  position: absolute;
  top: 324px;
  width: 420px;
}

.desktop .overlap-group-3 {
  background-image: url(../../assets/img/group-39205-6.png);
  background-size: 100% 100%;
  height: 270px;
  left: 0;
  position: absolute;
  top: 0;
  width: 420px;
}

.desktop .p {
  color: #000000;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 140px;
  letter-spacing: 0;
  line-height: 27.4px;
  opacity: 0.7;
  position: absolute;
  top: 172px;
  width: 900px;
}

.desktop .image {
  height: 950px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.desktop .overlap-wrapper {
  height: 100%;
  overflow: hidden;
}

.desktop .overlap-2 {
  height: 100%;
  position: relative;
  width: 100%;
}

.desktop .overlap-group-wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.desktop .overlap-group-4 {
  height: 100%;
  position: relative;
}

.desktop .rectangle {
  background: linear-gradient(
    180deg,
    rgba(23.22, 23.22, 23.22, 0.8) 0%,
    rgba(23, 23, 23, 0.57) 100%
  );
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.desktop .image-wrapper {
  height: 760px;
  left: 651px;
  position: absolute;
  top: 122px;
  width: 770px;
}

.desktop .img {
  height: 900px;
  left: 200px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 970px;
}

.desktop .building-a-stronger {
  color: transparent;
  font-family: "DM Serif Display", Helvetica;
  font-size: 93.2px;
  font-weight: 400;
  left: 120px;
  letter-spacing: 0;
  line-height: 99.5px;
  position: absolute;
  top: 100px;
}

.desktop .text-wrapper-4 {
  color: #fc6e01;
  font-size: 100.6px;
  font-style: italic;
}

.desktop .text-wrapper-5 {
  color: #ffffff;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 120px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 512px;
  width: 486px;
}

.desktop .group-6 {
  height: 60px;
  left: 120px;
  position: absolute;
  top: 648px;
  width: 175px;
}

.desktop .overlap-group-5 {
  background-color: #fc6e01;
  height: 60px;
  position: relative;
  width: 173px;
}

.desktop .text-wrapper-6 {
  color: #ffffff;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 18px;
  font-weight: 600;
  left: 40px;
  letter-spacing: 0.36px;
  line-height: 32px;
  position: absolute;
  top: 14px;
  white-space: nowrap;
}

.desktop .arrow-forward {
  height: 20px;
  left: 113px;
  position: absolute;
  top: 21px;
  width: 20px;
}

.desktop .group-7 {
  height: 79px;
  left: 131px;
  position: absolute;
  top: 20px;
  width: 1189px;
}

.desktop .mask-group {
  height: 79px;
  left: 0;
  position: absolute;
  top: 0;
  width: 73px;
}

.desktop .frame-2 {
  align-items: center;
  display: inline-flex;
  gap: 32px;
  justify-content: flex-end;
  left: 1050px;
  position: absolute;
  top: 18px;
}

.desktop .text-wrapper-7 {
  color: #ffffff;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  text-decoration: none;
}

.desktop .frame-3 {
  align-items: center;
  background-color: #fc6e01;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  justify-content: center;
  padding: 8px 20px;
  position: relative;
}

.desktop .text-wrapper-8 {
  color: #ffffff;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 27px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.desktop .arrow-forward-dp {
  height: 20px;
  position: relative;
  width: 20px;
}

.desktop .group-8 {
  background-image: url(../../assets/img/line-7-1.svg);
  background-size: 100% 100%;
  height: 1px;
  left: 0;
  position: absolute;
  top: 111px;
  width: 100%;
}

.desktop .group-9 {
  height: 516px;
  left: 150px;
  position: absolute;
  top: 950px;
  width: 554px;
}

.desktop .zero-poverty-strategy {
  color: transparent;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 44px;
  font-weight: 700;
  left: 150px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.desktop .div-2 {
  color: transparent;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 44px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.desktop .text-wrapper-9 {
  color: #060606;
}

.desktop .at-KADA-our-vision {
  color: #000000;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 90px;
  width: 550px;
}

.desktop .group-10 {
  height: 227px;
  left: 0;
  position: absolute;
  top: 320px;
  width: 388px;
}

.desktop .overlap-group-6 {
  background-color: #fff3ea;
  border-radius: 8px;
  height: 227px;
  position: relative;
  width: 382px;
}

.desktop .text-wrapper-10 {
  color: #000000;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 27px;
  font-weight: 400;
  left: 28px;
  letter-spacing: 0;
  line-height: 38.1px;
  position: absolute;
  top: 30px;
  width: 323px;
}

.desktop .image-2 {
  height: 11px;
  left: 28px;
  position: absolute;
  top: 32px;
  width: 15px;
}

.desktop .text-wrapper-11 {
  color: #000000;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 28px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 168px;
  white-space: nowrap;
}

.desktop .text-wrapper-12 {
  color: #000000;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 28px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 160px;
  white-space: nowrap;
}

.desktop .group-11 {
  height: 279px;
  left: 790px;
  position: absolute;
  
  top: 3100px;
  width: 600px;
}

.desktop .our-zero-poverty-2 {
  color: #000000;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 150px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 100px;
  width: 500px;
}

.desktop .group-12 {
  height: 253px;
  left: 140px;
  position: absolute;
  top: 4500px;
  width: 674px;
}

.desktop .the-multidimensional {
  color: #000000;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 150px;
  width: 670px;
}

.desktop .overlap-3 {
  background-image: url(../../assets/img/image.png);
  background-size: 100% 100%;
  height: 472px;
  left: 1050px;
  position: absolute;
  top: 1001px;
  width: 500px;
}

.desktop .img-wrapper {
  background-color: #ffffff;
  border-radius: 32px;
  height: 64px;
  left: 218px;
  position: relative;
  top: 204px;
  width: 64px;
}

.desktop .vector-2 {
  height: 28px;
  left: 24px;
  position: absolute;
  top: 18px;
  width: 22px;
}

.desktop .image-3 {
  height: 419px;
  left: 240px;
  position: absolute;
  top: 3082px;
  width: 549px;
}

.desktop .group-13 {
  height: 380px;
  left: 200px;
  position: absolute;
  top: 1595px;
  width: 386px;
}

.desktop .group-14 {
  height: 380px;
  left: 630px;
  position: absolute;
  top: 1595px;
  width: 386px;
}

.desktop .group-15 {
  height: 380px;
  left: 1060px;
  position: absolute;
  top: 1595px;
  width: 386px;
}

.desktop .group-16 {
  height: 845px;
  left: 0;
  position: absolute;
  top: 3601px;
  width: 100%;
}

.desktop .overlap-4 {
  height: 845px;
  position: relative;
}

.desktop .rectangle-2 {
  background-color: #fff1e7;
  height: 845px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.desktop .group-17 {
  height: 53px;
  left: 140px;
  position: absolute;
  top: 100px;
  width: 360px;
}

.desktop .text-wrapper-13 {
  color: #000000;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 44px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.desktop .group-18 {
  height: 512px;
  left: 140px;
  position: absolute;
  top: 233px;
  width: 370px;
}

.desktop .group-19 {
  height: 512px;
  position: relative;
}

.desktop .overlap-5 {
  background-image: url(../../assets/img/group-39205-2.png);
  background-size: 100% 100%;
  height: 320px;
  left: 0;
  position: absolute;
  top: 0;
  width: 370px;
}

.desktop .group-20 {
  background-color: #ffffff;
  border-radius: 28px;
  height: 56px;
  left: 157px;
  position: relative;
  top: 132px;
  width: 56px;
}

.desktop .group-21 {
  height: 192px;
  left: 0;
  position: absolute;
  top: 320px;
  width: 370px;
}

.desktop .group-22 {
  height: 192px;
  width: 374px;
}

.desktop .overlap-group-7 {
  background-color: #ffffff;
  height: 192px;
  position: relative;
  width: 370px;
}

.desktop .our-zero-poverty-3 {
  color: #000000;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 24px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 34px;
  width: 323px;
}

.desktop .text-wrapper-14 {
  color: #fc6e01;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 18px;
  font-weight: 600;
  left: 24px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 131px;
  white-space: nowrap;
}

.desktop .group-23 {
  height: 512px;
  left: 538px;
  position: absolute;
  top: 233px;
  width: 370px;
}

.desktop .group-24 {
  height: 512px;
  left: 936px;
  position: absolute;
  top: 233px;
  width: 370px;
}

.desktop .group-25 {
  height: 512px;
  left: 1334px;
  position: absolute;
  top: 233px;
  width: 370px;
}

.desktop .group-26 {
  height: 320px;
  left: 0;
  position: absolute;
  top: 0;
  width: 106px;
}

.desktop .group-27 {
  background-color: #ffffff;
  border-radius: 28px;
  height: 56px;
  left: 157px;
  position: absolute;
  top: 132px;
  width: 56px;
}

.desktop .vector-3 {
  height: 25px;
  left: 554px;
  position: absolute;
  top: 4792px;
  width: 19px;
}

.desktop .overlap-6 {
  background-color: #fff1e7;
  height: 1433px;
  left: 0;
  position: absolute;
  top: 7390px;
  width: 100%;
}

.desktop .group-28 {
  height: 430px;
  left: 840px;
  position: absolute;
  top: 100px;
  width: 600px;
}

.desktop .group-29 {
  height: 393px;
  left: 140px;
  position: absolute;
  top: 60px;
  width: 460px;
}

.desktop .promoting-health-and {
  color: transparent;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 44px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 371px;
}

.desktop .ensuring-the-health {
  color: #000000;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 150px;
  width: 456px;
}

.desktop .group-30 {
  height: 60px;
  left: 0;
  position: absolute;
  top: 350px;
  width: 175px;
}

.desktop .group-31 {
  height: 340px;
  left: 140px;
  position: absolute;
  top: 993px;
  width: 388px;
}

.desktop .group-32 {
  height: 340px;
  left: 140px;
  position: absolute;
  top: 613px;
  width: 600px;
}

.desktop .group-33 {
  height: 340px;
  left: 830px;
  position: absolute;
  top: 613px;
  width: 600px;
}

.desktop .group-34 {
  height: 340px;
  left: 580px;
  position: absolute;
  top: 993px;
  width: 400px;
}

.desktop .group-35 {
  height: 340px;
  left: 1030px;
  position: absolute;
  top: 993px;
  width: 400px;
}

.desktop .group-36 {
  height: 433px;
  left: 950px;
  position: absolute;
  top: 4884px;
  width: 566px;
}

.desktop .group-37 {
  height: 116px;
  left: 1100px;
  position: absolute;
  top: 4552px;
  width: 422px;
}

.desktop .group-38 {
  height: 116px;
  width: 424px;
}

.desktop .overlap-group-8 {
  background-color: #fff1e7;
  border-radius: 8px;
  height: 116px;
  position: relative;
  width: 422px;
}

.desktop .andhra-pradesh-s-MPI {
  color: #000000;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 20px;
  letter-spacing: 0;
  line-height: 29px;
  position: absolute;
  top: 15px;
  width: 381px;
}

.desktop .text-wrapper-15 {
  font-weight: 500;
}

.desktop .text-wrapper-16 {
  font-weight: 600;
}

.desktop .text-wrapper-17 {
  font-weight: 700;
}

.desktop .group-39 {
  height: 116px;
  left: 1100px;
  position: absolute;
  top: 4688px;
  width: 422px;
}

.desktop .significant {
  color: #000000;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 20px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 15px;
  width: 381px;
}

.desktop .group-40 {
  height: 433px;
  left: 150px;
  position: absolute;
  top: 4884px;
  width: 566px;
}

.desktop .group-41 {
  height: 643px;
  left: 100px;
  position: absolute;
  top: 6647px;
  width: 1240px;
}

.desktop .group-42 {
  height: 308px;
  left: 50px;
  position: absolute;
  top: 0;
  width: 608px;
}

.desktop .group-43 {
  height: 308px;
  left: 50px;
  position: absolute;
  top: 350px;
  width: 608px;
}

.desktop .group-44 {
  height: 660px;
  left: 700px;
  position: absolute;
  top: 0;
  width: 615px;
}

.desktop .empowering-our {
  color: transparent;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 40px;
  font-weight: 700;
  left: 140px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 6370px;
  white-space: nowrap;
}

.desktop .KADA-is-built-on-the {
  color: #000000;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 140px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 6470px;
  width: 800px;
}

.desktop .group-45 {
  height: 750px;
  left: 0;
  position: absolute;
  top: 8823px;
  width: 100%;
}

.desktop .overlap-7 {
  background-color: #643816;
  background-image: url(../../assets/img/group-46-1.png);
  background-size: 100% 100%;
  height: 750px;
  position: relative;
  width: 100%;
}

.desktop .stay-connected-with {
  color: transparent;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 31px;
  font-weight: 700;
  left: 140px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 110px;
  white-space: nowrap;
}

.desktop .text-wrapper-18 {
  color: #ffffff;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 140px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 167px;
  width: 468px;
}

.desktop .text-wrapper-19 {
  color: #ffffff;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 140px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 340px;
  width: 468px;
}

.desktop .text-wrapper-20 {
  color: #ffffff;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 140px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 374px;
  width: 468px;
}

.desktop .text-wrapper-21 {
  color: #ffffff;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 35%;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 683px;
  white-space: nowrap;
}

.desktop .text-wrapper-22 {
  color: #ffffff;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 140px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-decoration: underline;
  top: 550px;
  white-space: nowrap;
}

.desktop .text-wrapper-23 {
  color: #ffffff;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 304px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-decoration: underline;
  top: 550px;
  white-space: nowrap;
}

.desktop .text-wrapper-24 {
  color: #ffffff;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 431px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-decoration: underline;
  top: 550px;
  white-space: nowrap;
}

.desktop .ground-floor {
  color: #ffffff;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 140px;
  letter-spacing: 0;
  line-height: 29px;
  position: absolute;
  top: 414px;
  width: 468px;
}

.desktop .links-to-download {
  color: #ffffff;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 140px;
  letter-spacing: 0;
  line-height: 29px;
  position: absolute;
  top: 513px;
  width: 468px;
}

.desktop .contact-details {
  color: #ffffff;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 140px;
  letter-spacing: 0;
  line-height: 29px;
  position: absolute;
  top: 303px;
  width: 468px;
}

.desktop .group-46 {
  height: 453px;
  left: 1000px;
  position: absolute;
  top: 118px;
  width: 608px;
}

.desktop .line {
  height: 1px;
  left: 0;
  position: absolute;
  top: 660px;
  width: 100%;
}

.desktop .group-47 {
  height: 844px;
  left: 100px;
  position: absolute;
  top: 2122px;
  width: 1444px;
}

.desktop .overlap-8 {
  background-image: url(../../assets/img/group-46-2.png);
  background-size: 100% 100%;
  height: 844px;
  position: relative;
  width: 100%;
}

.desktop .kuppam-with-an-area {
  color: #000000;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 140px;
  letter-spacing: 0;
  line-height: 29px;
  position: absolute;
  top: 173px;
  width: 731px;
}

.desktop .text-wrapper-25 {
  color: #000000;
  font-family: "'Open Sans', sans-serif", Helvetica;
  font-size: 44px;
  font-weight: 700;
  left: 140px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 110px;
}

.desktop .group-48 {
  height: 396px;
  left: 140px;
  position: absolute;
  top: 300px;
  width: 706px;
}

.desktop .overlap-9 {
  height: 396px;
  position: relative;
}

.desktop .group-49 {
  height: 396px;
  left: 0;
  position: absolute;
  top: 0;
  width: 706px;
}

.desktop .group-50 {
  height: 116px;
  left: 0;
  position: absolute;
  top: 136px;
  width: 427px;
}

.desktop .overlap-group-9 {
  -webkit-backdrop-filter: blur(4px) brightness(100%);
  backdrop-filter: blur(4px) brightness(100%);
  background-color: #ffffff;
  height: 116px;
  position: relative;
  width: 423px;
}

.desktop .text-wrapper-26 {
  color: #181818cc;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 50px;
}

.desktop .div-3 {
  color: #181818;
  font-family: "Inter", Helvetica;
  font-size: 34px;
  font-weight: 400;
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 40px;
}

.desktop .text-wrapper-27 {
  font-size: 24px;
  font-weight: 500;
}

.desktop .rectangle-3 {
  -webkit-backdrop-filter: blur(4px) brightness(100%);
  backdrop-filter: blur(4px) brightness(100%);
  background-color: #fc6e01;
  height: 116px;
  left: 0;
  position: absolute;
  top: 20px;
  width: 8px;
}

.desktop .group-51 {
  height: 116px;
  left: 0;
  position: absolute;
  top: 0;
  width: 710px;
}
text .desktop .overlap-10 {
  -webkit-backdrop-filter: blur(4px) brightness(100%);
  backdrop-filter: blur(4px) brightness(100%);
  background-color: #ffffff;
  height: 116px;
  position: relative;
  width: 706px;
}

.desktop .group-52 {
  height: 116px;
  left: 443px;
  position: absolute;
  top: 136px;
  width: 267px;
}

.desktop .overlap-11 {
  -webkit-backdrop-filter: blur(4px) brightness(100%);
  backdrop-filter: blur(4px) brightness(100%);
  background-color: #ffffff;
  height: 116px;
  position: relative;
  width: 263px;
}

.desktop .text-wrapper-28 {
  color: #181818;
  font-family: "Inter", Helvetica;
  font-size: 34px;
  font-weight: 600;
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 75px;
}

.desktop .group-53 {
  height: 116px;
  left: 529px;
  position: absolute;
  top: 280px;
  width: 181px;
}

.desktop .overlap-12 {
  -webkit-backdrop-filter: blur(4px) brightness(100%);
  backdrop-filter: blur(4px) brightness(100%);
  background-color: #ffffff;
  height: 116px;
  position: relative;
  width: 177px;
}

.desktop .text-wrapper-29 {
  color: #181818cc;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 50px;
}

.desktop .frame-4 {
  align-items: center;
  display: inline-flex;
  gap: 25px;
  left: 0;
  position: absolute;
  top: 280px;
}

.desktop .group-54 {
  height: 116px;
  position: relative;
  width: 269px;
}

.desktop .overlap-group-10 {
  -webkit-backdrop-filter: blur(4px) brightness(100%);
  backdrop-filter: blur(4px) brightness(100%);
  background-color: #ffffff;
  height: 116px;
  position: relative;
  width: 265px;
}

.desktop .group-55 {
  height: 116px;
  margin-right: -4px;
  position: relative;
  width: 225px;
}

.desktop .overlap-13 {
  -webkit-backdrop-filter: blur(4px) brightness(100%);
  backdrop-filter: blur(4px) brightness(100%);
  background-color: #ffffff;
  height: 116px;
  position: relative;
  width: 221px;
}

.desktop .image-4 {
  height: 372px;
  left: 956px;
  object-fit: cover;
  position: absolute;
  top: 316px;
  width: 344px;
}
