.zero-poverty-container {
  display: flex;
  padding: 0px 40px;
}

.group-11 {
  max-width: 100%;
  text-align: left;
}

.image-3 {
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .zero-poverty-container {
    margin-top: 5em;
    margin-bottom: 5em;
  }
}

@media (max-width: 768px) {
  .zero-poverty-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .zero-poverty-strategy{
    flex-direction: column;
  }

  .image-3 {
    width: 100%;
  }
}

.orange-title {
  color: #fc6e01;
  margin-right: 5px;
  font-weight: 700;
  font-size: 44px;
}

.zero-poverty-strategy {
  display: flex;
  font-size: 44px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  align-items: center;
  justify-content: start;
}

.our-zero-poverty {
  line-height: 30px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  max-width: 100%;
  text-align: justify;
  /* word-break: break-all; */
  /* hyphens: auto; */
}
