body {
  margin: 0;
  font-family: "Josefin Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-menu-item-selected {
  border-bottom-color: #fff !important;
}

.great-vibes-regular {
  font-family: "Great Vibes", cursive;
  font-weight: 400;
  font-style: normal;
}

h2 {
  font-family: "Great Vibes", cursive;
  font-weight: 400;
  font-style: normal;
}
