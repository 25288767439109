.success-container {
  padding: 20px 40px;
  margin-bottom: 2em;
}
.success-title {
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 1em;
  font-family: "Roboto", sans-serif;
  text-align: center;
  color: #fc6e01;
}
.scrollable-row {
  display: flex;
  justify-content: space-between;
  scroll-behavior: smooth;
  padding-bottom: 20px;
}

:where(.css-dev-only-do-not-override-1maqleu).ant-card .ant-card-cover > * {
  width: 100%;
  border-radius: 2px 2px 0 0;
  display: block;
}
.story-card {
  height: auto;
  width: 100%;
  margin-right: 20px;
  transition: transform 0.6s;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  box-shadow: 2px 2px 5px #e4e4e4;
}

.story-card:hover {
  transform: scale(1.03);
}

.story-video {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 2px 2px 0 0;
}

.description {
  margin: 10px 0;
}

.person {
  font-weight: bold;
  color: #555;
}

/* .scrollable-row::-webkit-scrollbar {
  display:none;
} */

@media (max-width: 768px) {
  .story-card {
  }
  .driving-change {
    font-size: 2em;
  }
  .scrollable-row {
    flex-direction: column;
    gap: 15px;
  }
}

.innovation-introduction {
  font-size: 16px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  line-height: 30px;
  /* margin: 10px 0; */
  margin-bottom: 3em;
  width: 100%;
  text-align: justify;
}

.driving-change {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}
.driving-change-text {
  font-size: 44px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  margin-right: 0.3em;
  display: inline-block;
  margin-bottom: 5px;
}

.group-wrapper {
  margin-bottom: 3em;
}

.overlap {
  margin-top: 3em;
  margin-bottom: 3em;
}

.success.stories.img {
  height: 400px;
  /* width: 320px; */
  /* width: 100%; */
}
