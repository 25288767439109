@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

.vision-container {
  /* margin: 3em, 1em; */
  /* margin-top: 3em; */
  /* margin-bottom: 3em; */
  /* display: flex; */
  /* flex-direction: column; */
  padding: 20px;
}

/* Main vision content */
.group-9 {
  height: auto; /* Allow height to adjust based on content */
  max-width: 100%;
  flex: 1;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box; /* Ensure padding is considered in total height */
  overflow: hidden;
  gap: 10px; /* Prevent overflow */
}
.vision-video-container {
  height: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
}

.vision-header {
  display: flex;
  align-items: baseline;
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 10px;
  font-family: "Open Sans", sans-serif;
  padding: 0px;
  margin-top: 0px;
  /* margin-right: 15px; */
}
.pdf-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Adds a paper-like shadow */
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.pdf-container:hover {
  transform: scale(1.03); /* Slight zoom on hover */
}

.pdf-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 8px;
}

.pdf-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(255, 0, 0, 0.9);
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.vision-title {
  font-weight: bold;
}

.vision-year {
  font-weight: lighter;
}

.vision-description {
  margin-top: 0px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 10px;
  line-height: 30px;
  text-align: justify;
}

.group-10 {
  display: flex;
  font-family: "Open Sans", sans-serif;
}

/* Quote and CM's message styling */
.overlap-group-6 {
  background-color: #fff3ea;
  border-radius: 8px 8px 0px 0px;
  padding: 20px;
  width: 100%;
  max-width: 100%; /* Ensure no overflow beyond parent */
  box-sizing: border-box; /* Ensures padding is considered in total width */
  font-family: "Open Sans", sans-serif;
  margin-top: 22px;
}

.overlap-group-6-1 {
  border-radius: 0px 0px 8px 8px;
  padding: 20px;
  width: 100%;
  max-width: 100%; /* Ensure no overflow beyond parent */
  box-sizing: border-box; /* Ensures padding is considered in total width */
  font-family: "Open Sans", sans-serif;
  margin-top: 22px;
  border: 1px solid #e4e4e4;
  margin-top: 0px;
  text-align: center;
}

.vision-goal {
  text-align: center;
  margin-top: 0;
  font-size: 30px;
  margin-bottom: 10px;
  font-weight: 600;
}

.quote-image {
  width: 15px;
  /* margin: 10px 0; */
}

.vision-cm-title {
  font-size: 16px;
  text-align: center;
  margin: 0 0 10px 0; /* Adds space below the text */
  font-weight: 400;
  line-height: 1.6; /* Adjusts the spacing between lines */
}

.vision-cm-name {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}

/* Right-side image */
.overlap-3 {
  /* background-image: url(../../assets/img/image.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 500px;
}

.img-wrapper {
  display: flex;
  justify-content: center;
}

.vector-2 {
  width: 100%;
}

/* Row of images below vision */
.images-row {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 0%;
}

.vision-image-item {
  flex: 1;
  margin-right: 10px;
  max-width: 100%;
}

.vision-image-item:last-child {
  margin-right: 0;
}

.vision-video {
  width: 100%;
  /* margin-left: 1em; */
  margin-top: 5em;
}

.vision-img {
  border-radius: 10px;
  height: 300px;
  object-fit: cover;
}

.icon-cards {
  width: 100%;
  height: 260px;
  border-radius: 10px;
  /* padding: 10px; */
  display: flex;
  justify-content: center;
  margin: 10px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.icon-cards:hover {
  transform: scale(1.05);
}

/* Flex container for text and image */
.icon-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 1;
}

/* Left Side: Text (50% width) */
.icon-text {
  width: 100%;
  display: flex;
  flex-direction: column; /* Stack text vertically */
  justify-content: space-between;
}

/* Right Side: Image (50% width) */
.icon-image {


}

.icon-image img {
   /* Adjust image size as needed */
  height:  85px;
  object-fit: contain;
}

/* Responsive styling */
@media (min-width: 768px) {
  .vision-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .group-9 {
    flex: 1;
  }

  .overlap-3 {
    flex: 1;
    margin-left: 20px;
  }

  .vision-header {
    /* font-size: 2rem; */
    justify-content: left;
  }

  .vision-goal {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .images-row {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }

  .vision-video {
    margin-top: 6em;
  }
}

@media (max-width: 768px) {
  .vision-container {
    margin-top: 1em;
    margin-bottom: 1em;
    flex-direction: column;
  }

  .group-9 {
    margin-bottom: 20px;
  }

  .images-row {
    flex-direction: column;
  }

  .vision-image-item {
    margin-bottom: 10px;
  }

  .vision-image-item:last-child {
    margin-bottom: 0;
  }

  .vision-video {
    border-left: none;
    border-top: none;
    width: 100%;
  }
}
