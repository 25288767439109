/* :root {
  --primary-color: #f1df56;
  --secondary-color: #ffffff;
  --text-color: #000000;
  --footer-bg: #f1df56;
  --gradient-angle: 135deg;
} */

/* * {
  color: var(--text-color) !important;
} */

a {
  color: #000;
}

/* Animation classes */
.animate-title-text,
.animate-left,
.animate-right {
  opacity: 0;
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.animate-title-text {
  transform: translateY(20px);
}

.animate-left {
  transform: translateX(-40%);
}

.animate-right {
  transform: translateX(40%);
}

.animate-title-text.visible,
.animate-left.visible,
.animate-right.visible {
  opacity: 1;
  transform: translateX(0) translateY(0);
}

/* Flip icon effect */
.flip-icon-onhover {
  text-align: center;
  perspective: 1000px;
}

.flipping-image {
  transition: transform 0.6s ease;
  transform-style: preserve-3d;
}

.flip-icon-onhover:hover .flipping-image {
  transform: rotateY(360deg);
}

/* Carousel styles */

.carousel-slide {
  height: 100%;
  /* background: linear-gradient(var(--gradient-angle), #003366 50%, #dc143c 50%); */
}

.carousel-content {
  display: flex;
  height: 100%;
  color: white;
}

.carousel-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}

.carousel-text h2,
.carousel-text p {
  opacity: 0;
  transform: translateX(-100%);
  animation: slideIn 1s forwards;
}

.carousel-text h2:nth-child(1) {
  animation-delay: 0.2s;
}

.carousel-text h2:nth-child(2) {
  animation-delay: 0.4s;
}

.carousel-text h2:nth-child(3) {
  animation-delay: 0.2s;
}

.carousel-text p {
  animation-delay: 0.8s;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.carousel-logo {
}
.carousel-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-image img {
  max-width: 700px;
}

.landing-header {
  background-color: #000;
  position: fixed;
  width: 100%;
}

.hero-bg {
  position: relative;
  background: transparent;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.hero-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../assets//newLanding/banner-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  filter: brightness(35%) blur(2px);
  z-index: -1;
}

@media (max-width: 768px) {
  .hero-bg {
    background-size: auto 100%;
  }
}

.tdp-bg {
  background-color: var(--primary-color);
}
.footer-bg {
  /* text-shadow: 2px 2px 8px #000000; */

  position: relative;
  margin-top: calc(var(--zigzag-height) * -1);
  padding-top: var(--zigzag-height);
  overflow: hidden; /* Prevent overflow if needed */
}

.footer-bg::before {
  content: "";
  background: url("../../assets//newLanding/footer-bg.jpg") no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  filter: brightness(60%); /* Adjust brightness as needed */
}

.footer-bg::after {
  content: "";
  background-color: rgba(
    124,
    55,
    9,
    0.5
  ); /* Apply a brown color with transparency */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; /* Ensure it's behind content */
  mix-blend-mode: multiply; /* Blend the brown color with the background image */
}

.text-white {
  color: var(--secondary-color) !important;
}

.transparent {
  background: transparent;
  border: transparent;
  outline: transparent;
}

.header-menu-items .ant-menu-item,
.header-menu-items .ant-menu-submenu-title {
  color: white !important;
}
.login-button:hover .arrow-icon {
  transform: translateX(5px); /* Adjust the value for desired movement */
}
.stats-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100px;
  background-color: #fff3ea;
  /* background-image: url("../../assets/images/stats-bg.jpg"); */
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
}

.zero-poverty-strategy {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100px;
  background-color: #fff;
  /* background-image: url("../../assets/images/stats-bg.jpg"); */
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
}

.success-stories-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100px;
  background-color: #fff3ea;
  /* background-image: url("../../assets/images/stats-bg.jpg"); */
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
}

.innovation-container {
  position: relative;
  /* width: 100%; */
  height: 100%;
  min-height: 100px;
  background-color: #fff3ea;
  padding: 0px 40px;
  /* background-image: url("../../assets/images/stats-bg.jpg"); */
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
}

.promoting-health-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100px;
  background-color: #fff3ea;
  /* background-image: url("../../assets/images/stats-bg.jpg"); */
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
}

.stats-blur {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(1px);
  /* background-color: rgba(0, 0, 0, 0.1); */
  background-color: #fff3ea;
}

.join-us-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
  background-image: url("../../assets/images/join-us-bg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
}

.join-us-blur {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(10px);
  background-color: rgba(43, 10, 88, 0.5);
}

.join-us-content {
  position: relative;
  z-index: 2;
}

.stats-content h1,
.stats-content h2,
.stats-content h3,
.stats-content h4,
.stats-content h5,
.stats-content h6,
.stats-content label,
.stats-content .stats-content,
.stats-content {
  /* color: var(--secondary-color) !important; */
}
/* Ensure all text is white and readable */
.join-us-content h1,
.join-us-content h2,
.join-us-content h3,
.join-us-content h4,
.join-us-content h5,
.join-us-content h6,
.join-us-content label,
.join-us-content .join-us-content,
.join-us-content {
  color: white !important;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -16px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 16px; /* gutter size */
  background-clip: padding-box;
}

input {
  min-height: 40px;
}

.anek-telugu {
  font-family: "Anek Telugu", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings: "width" 100;
}

/* .orange-title {
  color: #fc6e01;
  margin-right: 5px;
} */

.floating-grievance {
  bottom: 0;
  right: 0;
  position: fixed;
  margin: 10px;
}

.floating-grievance .ant-btn {
  background-color: #fc6e01;
  color: #fff;
}

.grievance-modal {
  /* max-width: 600px; */
  min-width: 1000px;
  /* width: 50svh;
  height: 50svh; */
  /* transform: translate(-50%, 50%); */
}

.cm-image {
  width: 100%;
  max-width: 900px;
  height: auto;
}

@media (max-width: 1600px) {
  .cm-image {
    max-width: 800px;
  }
}

@media (max-width: 1280px) {
  .cm-image {
    max-width: 500px;
  }
}

.parent-container {
  width: "auto";
  height:"auto";
  overflow-x: hidden;
}
