.overlap-6 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 3em;
  padding: 0px 20px;
}

.first-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.first-row img {
  width: 100%;
}

.left-column {
  flex: 1;
  margin-right: 20px;
}

.right-column {
  flex: 1;
}

.second-row,
.third-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.second-row img {
  width: 100%;
}

.third-row img {
  width: 33%;
}

@media (max-width: 768px) {
  .first-row,
  .second-row,
  .third-row {
    flex-direction: column;
  }

  .second-row img,
  .third-row img {
    width: 100%; /* Full width for mobile view */
    margin-bottom: 20px;
  }
}

.ensuring-the-health {
  font-weight: 400;
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: justify;
  font-size: 16px;
  line-height: 32px;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
}

.join-us {
  margin-top: 2em;
  margin-bottom: 2em;
}

.group-31,
.group-32,
.group-33 {
  width: 100%;
}

.success-stories-img {

  width: 30%;
  height: auto;
 
  overflow: hidden;
}
img {
  width: 100%; /* Makes the image responsive */
  height: auto; /* Maintains the aspect ratio */
  object-fit: cover; /* Ensures the image covers the container without stretching */
}

.scrollable-row .ant-card .ant-card-body {
  display: flex;
  width: 100%;
  height: auto;
  text-align: left;
  justify-content: center;
  overflow-y: auto;
  padding: 20px;
}

.promoting-health-and {
  font-size: 44px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
}

::-webkit-scrollbar {
  width: 8px; 
  height: 12px; 

}
::-webkit-scrollbar-thumb {
  background: #fc6e01;
  border-radius: 10px; 
}

::-webkit-scrollbar-thumb:hover {
  background: #f1751c; 
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px; 
}

::-webkit-scrollbar-corner {
  background: #f1f1f1; 
}
