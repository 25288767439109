.stats-row {
  display: flex;
  flex-wrap: wrap;
  padding :0px 40px;
  margin: 0px;
}

.statistics-container {
  padding: none;
}

.statistics-heading {
  font-size: 44px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 20px;
}

.kuppam-description {
  font-size: 1rem;
  line-height: 1.6;
  text-align: justify;
}

.statistics-content {
  margin-top: 20px;
  /* font-family: Inter; */
}

/* .stats-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
} */

.stat-item {
  background-color: #fff;
  padding: 10px 5px;
  /* margin: 5px 0px; */
  /* border-radius: 8px; */
  /* text-align: center; */
  border-left: 10px solid #fc6e01;
}

.stat-label {
  padding-left: 15px;

  font-size: 18px;
  font-weight: 500;
  font-family: Inter;
}

.stat-value {
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-size: 30px;
  font-weight: 600;
  margin-top: 8px;
  padding-left: 15px;
  color: #181818;
  font-family: Inter;
  /* background-color: #fff; */
}

.stat-value span {
  font-size: 20px;
  font-weight: 500;
  font-family: Inter;
}

.image-container {
  display: flex;
  justify-content: right;
  align-items: center;
  height: 100%;
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #747474);
  transition: transform 0.5s;
}
.image-container:hover{
  transform: scale(1.1);
}
.image {
  max-width: 80%;
  height: auto;
  border-radius: 8px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .statistics-container {
    padding: 10px;
   
  }

  .kuppam-description {
    font-size: 1rem;
  }

  /* .stat-item {
    padding: 10px;
  } */
  /* 
  .stats-grid {
    grid-template-columns: 1fr;
  } */
}

@media (min-width: 768px) {
  .statistics-container {
    margin-bottom: 4em;
  }

  .kuppam-description {
    font-size: 1rem;
    margin-bottom: 3em;
  }

  /* .stat-item {
    padding: 20px;
  } */
  /* 
  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  } */
}
