.toolbar-menu {
  background: transparent;
  font-size: 16px;
}

.toolbar-menu.ant-menu-horizontal {
  border-bottom: none !important;
}

.toolbar-menu.ant-menu-horizontal > .ant-menu-item::after {
  border-bottom: none !important;
}
