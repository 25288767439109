.layout {
  margin: 0px !important;
  padding: 0px !important;
}

.layout-header {
  padding: 0px 40px;
  height: 140px !important;
  background: radial-gradient(circle at 10% 20%, #fee8d9 0%, #fce1ce 90%);
  /* background: linear-gradient(to right, rgb(250, 245, 198), rgb(232, 245, 236)); */
  /* background: linear-gradient(
    to right,
    rgba(233, 138, 48, 0.5),
    rgba(242, 101, 36, 0.5)
  ); */
}

.layout-body {
  background-color: #f7f7f7;
  padding: 20px 40px;
  min-height: calc(100vh - 22vh) !important;
}

.layout-public-body {
  /* background-color: #5a3d26 !important; */
  /* background-image: url("../assets/images/background.png"); */
  padding: 20px 40px;
  min-height: calc(100vh - 200px) !important;
}

.layout-public-footer {
  height: 60px !important;
  background-color: #5a3d26 !important;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
}

.layout-footer {
  height: 5vh !important;
  text-align: center;
}

.layout-header-public {
  height: 15vh !important;
  background: transparent;
}

.layout-body-public {
  background-color: transparent;
  min-height: calc(100vh - 18vh) !important;
}
