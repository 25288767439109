.gallery-page {
    width: 100vw;;
    height: 100vh;
}

.gallery-header {
    width: 90vw;
    padding: 0px 72px;
    height: auto;
    padding-bottom: 10px;
    border-bottom: 1px solid #D9D9D9;

}

.gallery-image-section{
    padding: 20px 80px;
    /* height: 90%;
    overflow-y: auto; */
}
.gallery-image-container{
    padding: 20px 0px;
}

.gallery-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 100vw;
    gap: 10px;
}
.gallery-segmented{
    width: 100%;
    margin: 10px 0px;
}

.gallery-image-title{
    font-size: 30px;
    margin-top: 0% !important;
    margin-bottom: 10px !important;
}

.ant-image-preview-body {
width: 70% !important;
height: auto !important;
margin-left: 15%;
    /* max-width: 80vw !important;
    max-height: 80vh !important; */
 
  }
  .ant-image-preview{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  
  /* .ant-image-preview-img {
    object-fit: cover;
  } */