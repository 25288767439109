.community-empowerment {
  max-width: 100%;
  /* margin: 0 auto; */
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.empowering-our {
  font-size: 2.5rem;
  color: #333;
  /* text-align: center; */
  margin-bottom: 20px;
}

.introduction {
  max-width: 600px;
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
  text-align: justify;
  margin-bottom: 30px;
}

.group-41 {
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-start;
  width: 100%;
}

.image-column {
  display: flex;
  flex-direction: column;
}

.group-42,
.group-43 {
  width: 100%;
  margin-bottom: 15px;
  max-width: 700px;
}

.group-44 {
  width: 100%;
  max-width: 700px;
}

@media (max-width: 768px) {
  .group-41 {
    flex-direction: column;
    align-items: center;
  }

  .image-column,
  .group-44 {
    max-width: 100%;
    margin: 0 auto 15px;
  }

  .group-44 {
    margin-top: 15px;
  }

  .introduction {
    max-width: 90%;
  }
}

@media (min-width: 768px) {
  .group-42,
  .group-43 {
    width: 100%;
    /* margin-bottom: 30px; */
    /* margin-right: 15px; */
    max-width: 700px;
  }
  .group-44 {
    width: 100%;
    max-width: 700px;
    /* margin-left: 15px; */
  }
}

/* @media (max-width: 480px) {
  .empowering-our {
    font-size: 1.8rem;
  }

  .group-42,
  .group-43,
  .group-44 {
    max-width: 100%;
  }
} */

.empowerment-title-and-description {
  width: 100%;
}

.quote-content {
  font-family: Barlow;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}

.community-empowerment-quote {
  background-color: #fff1e7;
  border-radius: 8px;
  position: relative;
  width: 90%;
  padding: 1em 1em;
  margin-bottom: 15px;
}

  .empowerment-content {
    background-color: #f7f7f7;
    border-radius: 8px;
    position: relative;
    /* width: 90%; */
    padding: 1em 1em;
    /* margin-bottom: 30px; */
    font-family: Barlow;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    width: 70%;
    flex-direction: column;
  }
