.group-45 {
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: #fff;
  margin-top: 3em;
}

.first-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.left-column {
  flex: 1;
  margin-right: 20px;
}

.right-column {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stay-connected-with {
  font-size: 30px;
  font-weight: 700;
  line-height: 37px;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 20px;
}

.text-wrapper-18 {
  margin-top: 0;
  margin-bottom: 2em;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  font-family: 'Open Sans', sans-serif;
}

.contact-details div,
.links-to-download div {
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  font-family: 'Open Sans', sans-serif;
}
.contact-details h3{
  font-family: 'Open Sans', sans-serif;

}
.contact-details p {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  font-family: 'Open Sans', sans-serif;
}

.contact-details span,
.links-to-download span {
  font-size: 18px;
  font-weight: 700;
  line-height: 29px;
  font-family: 'Open Sans', sans-serif;
}

.download-items {
  display: flex;
  gap: 1em;
}

.download-item {
  font-size: 1rem;
  cursor: pointer;
  text-decoration-line: underline;
}

@media (max-width: 768px) {
  .first-row {
    flex-direction: column;
  }

  .left-column,
  .right-column {
    width: 100%;
    margin-right: 0;
  }

  .right-column {
    margin-top: 20px;
  }

  .download-items {
    flex-direction: column;
  }
}

.address {
  margin-top: 0;
  margin-bottom: 2em;
}       
