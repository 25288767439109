.poverty-section {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between rows */
  padding: 20px 40px;
}

.row {
  display: flex;
  /* justify-content: space-evenly; */
  align-items: flex-start;
  gap: 20px;
}

.first-row {
  flex-wrap: wrap;
  gap: 5%;
}

.group-12 {
  flex: 1;
  min-width: 50%;
}

.info-column {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 35%;
  min-height: 330px;
  
}

.group-12 .div-2 {
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

.group-12 .the-multidimensional {
  width: 90%;
  margin-top: 10px;
  font-weight: 400;
  line-height: 32px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  text-align: justify;
  color: #555;
}

.group-37 .andhra-pradesh-s-MPI,
.group-39 .significant {
  font-size: 1.2rem;
  color: #333;
  text-align: justify;
  /* padding-left: 10px; */
}

.second-row {
  display: flex;
  /* justify-content: space-evenly; */
  gap: 25%;
}

.group-40,
.group-36 {
  max-width: 100%;
  /* height: auto; */
  /* object-fit: contain; */
}

@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }

  .group-40,
  .group-36 {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .poverty-section {
    margin-top: 3em;
    margin-bottom: 3em;
  }
}

.orange-title {
  color: #fc6e01;
  font-size: 44px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
}

.overlap-group-8 {
  background-color: #fff1e7;
  border-radius: 8px;
  position: relative;
  width: 100%;
  padding: 20px 20px;
  /* margin-bottom: 1em; */
}

.group-37,
.group-39 {
  margin-top: 3em;
  width: 100%;
}

.andhra-pradesh-s-MPI .text-wrapper-15,
.significant .text-wrapper-15 {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  font-family: 'Open Sans', sans-serif;
}

.andhra-pradesh-s-MPI .text-wrapper-17,
.significant .text-wrapper-17 {
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  line-height: 40px;
}

.andhra-pradesh-s-MPI,
.significant {
  margin: 0;
}
